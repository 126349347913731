@import "src/scss/common";

aside.main-side-menu {
	@include shadow-card-2();

	display: flex;
	flex-direction: column;
	background-color: white;

	min-width: 200px;
	height: 100%;
	z-index: 100;


	h2.menu-label {
		user-select: none;
		font-size: 0.6rem;
		padding-left: 0.75rem;
		font-weight: bold;
		color: $cs2;

		&:first-child {
			padding-top: 1rem;
		}
	}

	.menu-list {
		li {

			user-select: none;


			a {
				padding-left: 1.2rem;
			}

			.menu-item-link {
				position: relative;
				border-radius: 0;
				user-select: none;
				-webkit-user-drag: none;

				.icon-text {
					display: flex;

					.icon {
						padding-right: 5px;
					}

					.menu-item-label {
						flex: 1;
						position: relative;
						top: 1px;
						font-weight: 500;
					}
				}
			}
		}
	}


}
