.up-pb-component-list-group-wrapper {
    display: flex;
}

.up-pb-component-list-group {
    width: 50%;

    > div {
        transform: none !important; // Stop dnd doing weird bouncies
        padding: 5px;
    }

    [data-rbd-placeholder-context-id] {
        display: none !important;
    }
}

.up-pb-component-list-component {
    padding: 5px;
    cursor: pointer;
    border: solid 1px #eee;
    background: rgba(255, 255, 255, 0.5);

    &:hover {
        border-color: #aaa;
    }

    img {
        width: 100%;
        pointer-events: none;
        display: block;
    }
}

.dragging {
    background: #efefef;
}
