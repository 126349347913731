.up-pb-canvas {
    flex-grow: 1;
    padding: 30px;
    overflow: auto;

    .content {
        background: #fff;
        max-width: 1000px;
        margin: 0 auto 30px auto;
    }

    section.section {
        background: #fff;
        border: dotted 1px #ddd;
    }

    .up-pb-canvas-body {
        min-height: 100px;

        section.section {
            padding: 1em;
        }

        div.column {
            &.is-dragging-over {
                overflow: hidden;
            }

            &:empty,
            &.is-empty {
                min-height: 125px;
                background-image: repeating-linear-gradient(
                                -45deg,
                                #f5f5f5,
                                #f5f5f5 10px,
                                #fff 10px,
                                #fff 20px
                );

                background-clip: content-box;
                -webkit-background-clip: content-box;

                &:after {
                    content: attr(label);
                    height: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    font-weight: 700;
                }
            }

            &.is-dragging-over {
                overflow: hidden;
                animation: slidebg 10s linear;
                animation-iteration-count: infinite;
                background-image: repeating-linear-gradient(
                                -45deg,
                                #fffbf5,
                                #fffbf5 10px,
                                #fff7eb 10px,
                                #fff7eb 20px
                );
            }
        }
    }
}

// Not here..? Would be more like a user-predefined footer/header
.up-pb-canvas {
    .footer {
        padding: 15px
    }
}

@keyframes slidebg {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 0 -300px;
    }
}
