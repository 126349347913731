.image-element {
    width: 100%;

    img {
        display: block;
    }
}

.el-image-empty img {
    width: 100%;
}
