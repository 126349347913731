@charset "utf-8";
@import "src/scss/common";

.elevated-auth-required-container {
    border: dashed 1px $grey-lighter;
    padding: 1.25rem;

    p {
        font-weight: 500;
        margin-bottom: 0.75rem;
        font-size: 1rem;
    }
}
