.up-pb-delete-zone {
    position: fixed;
    width: 100px;
    height: 100px;
    bottom: 20px;
    right: 20px;
    background: firebrick;
    color: #fff;
    font-size: 1.8em;
    z-index: 299;

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 4px #aaa;

    // Stop dnd doing weird spacies
    [data-rbd-placeholder-context-id] {
        display: none !important;
    }
}
