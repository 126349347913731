.up-pb-element-settings {
    position: relative;

    form.with-tabs .modal-card-body .dynamic-form-sections .tab-section {
        padding: 10px;
    }

    .delete {
        position: absolute;
        right: 0;
        margin: 10px;
    }

    .modal-card-body {
        padding: 0;
    }

    .tabs {
        margin-bottom: 0 !important;
    }


    .up-accordion {
        margin: -10px;
    }
}
