$panel-side-width: 300px;
$panel-bottom-height: 250px;

.up-pb-panel {
    box-sizing: border-box;
    flex-shrink: 0;
    overflow: auto;
    transition: margin ease-out 0.15s;
    background: #fff;
    border: solid 1px #eee;

    &.up-pb-panel-left,
    &.up-pb-panel-right {
        width: $panel-side-width;
    }

    &.up-pb-panel-left {
        margin-left: -$panel-side-width;
    }

    &.up-pb-panel-right {
        margin-right: -$panel-side-width;
    }

    &.up-pb-panel-bottom {
        height: $panel-bottom-height;
        margin-bottom: -$panel-bottom-height;
    }

    &.up-pb-panel-left.is-visible {
        margin-left: 0;
    }

    &.up-pb-panel-right.is-visible {
        margin-right: 0;
    }

    &.up-pb-panel-bottom.is-visible {
        margin-bottom: 0;
    }
}
