@import "src/scss/common";

.group-settings {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(flex-end);
    @include align-content(center);
    @include justify-content(flex-start);
    @include flex-direction(row);

    .group-settings-fields {
        @include flex-grow(1);
    }

    .group-settings-actions {
        @include flexbox();
        @include flex-grow(1);
        @include justify-content(flex-end);
        @include align-items(flex-end);

        height: 100%;
    }
}
