@charset "utf-8";

@import "src/scss/common";

.role-required {
    @include flexbox();
    @include flex-grow(0);
    @include justify-content(stretch);
    @include align-items(flex-start);
    @include align-content(flex-start);
    @include flex-direction(row);

    border: dashed 1px $red;
    padding: 1.25rem;

    span {
        color: $red;
        font-weight: 500;
        font-size: 1rem;
        margin: 0;
    }

    .icon {
        margin-right: 0.2rem;
    }
}
