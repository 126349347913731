@import "src/scss/common";

.environments-outer {
    .environment-container {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        &:first-child {
            padding-top: 0;
        }

        .environment-container-title {
            color: $grey-dark;
            font-size: 0.75em;
            font-weight: 600;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            padding: 0.25rem 0 0.5rem 0.15rem;
        }

        .environment-container-groups {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(flex-start);
            @include align-content(flex-start);
            @include flex-direction(column);
        }

        .no-env-msg {
            font-weight: 600;
            font-size: 0.8rem;
            margin: 0.5rem 0.25rem;
            margin-bottom: 0;
            color: $grey-dark;
        }
    }
}
