@import "src/scss/common";

.group-variables {
    @include flex-grow(1);
    padding: 0.75rem;
    background-color: $tile-bg-alt5;

    .new-variable-headers {
        color: #363636;
        display: block;
        font-size: 0.9rem;
        font-weight: 700;
        margin-bottom: 0.5rem;

        &.group-locked {
            margin-bottom: 0;
        }
    }

    .new-variable,
    .new-variable-headers {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(center);
        @include justify-content(space-between);

        .padding {
            @include flex-grow(1);
            padding-right: 10px;
        }

        .field-name,
        .field-value {
            @include align-self(flex-start);
            @include align-items(flex-start);

            padding-right: 10px;

            // Try and force both to take up equal width.
            @include flex-grow(1);
            width: 39%;
        }

        .field-secret,
        .field-locked {
            @include flexbox();
            @include flex-grow(0);
            @include align-items(center);
            @include justify-content(center);

            padding-right: 10px;

            &.disabled {
                color: $grey-light;
            }

            width: 7%;
        }

        .field-actions {
            @include flex-shrink(1);
            @include flex-grow(0);

            width: 40px;
        }
    }

    .new-variable {
        .field-secret,
        .field-locked,
        .field-actions {
            // So errors don't cause these to shift down.
            height: 40px;
            margin-bottom: auto;
        }
    }
}

.group-variable-items {
    border-top: solid 1px $tile-inner-border-alt1;

    .group-variable {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(center);
        @include justify-content(space-between);

        padding: 0.75rem;

        &:hover {
            background-color: $tile-bg-alt3;
        }

        .field-name,
        .field-value {
            @include flex-grow(1);
            padding-right: 10px;
        }

        .field-secret,
        .field-locked {
            @include flexbox();
            @include flex-grow(0);
            @include align-items(center);
            @include justify-content(center);

            padding-right: 10px;

            &.disabled {
                color: $grey-light;
            }

            width: 7%;
        }

        .field-actions {
            @include flex-shrink(1);
            @include flex-grow(0);

            width: 40px;
        }

        &.is-inherited {
            input {
                background-color: $tile-bg-alt1;
            }

            &:hover {
                input {
                    background-color: #FFF;
                }
            }
        }

        &.has-edit {
            .field-value {
                input {
                    background-color: $yellow-light-background;

                    &:focus {
                        background-color: #FFF;
                    }
                }
            }
        }
    }
}
