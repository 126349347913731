@import "src/scss/common";

.environment-group-new-outer {
    @include flex-grow(1);
    width: 100%;

    padding: 0.5rem 0.25rem;

    &:last-child {
        padding-bottom: 0;
    }

    .environment-group-new {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(center);
        @include align-content(center);
        @include justify-content(flex-start);
        @include flex-direction(row);

        border: dashed 1px $grey-lighter;
        //box-shadow: 2px 2px 8px -3px #b9b9b9;

        color: $grey-dark2;
        padding: 0.75rem;
        cursor: pointer;

        &:hover {
            color: $blue;
            border-color: $blue;
        }
    }
}

.environment-group-outer {
    @include flex-grow(1);
    width: 100%;

    padding: 0.5rem 0.25rem;

    &:last-child {
        padding-bottom: 0;
    }

    .environment-group {
        @include flex-grow(1);
        width: 100%;

        background-color: #FFF;
        border: solid 1px $tile-inner-border-alt1;
        border-radius: 0.25rem;
        overflow: hidden;


        &.open {
            @include shadow-card-2();
            border: none;
        }

        &:not(.open){
            border-bottom: none;
        }

        .environment-group-bar {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(stretch);
            @include align-content(center);
            @include flex-direction(row);

            background-color: $tile-bg-alt1;
            border-bottom: solid 1px $tile-inner-border-alt1;

            .start,
            .middle,
            .end {
                padding: 0.5rem;
                cursor: pointer;
            }

            .start,
            .middle {
                @include flexbox();
                @include align-self(flex-start);
                @include align-items(center);
                @include align-content(center);

                padding-left: 0.5rem;
                font-weight: 550;
                color: $grey-dark;
            }

            .start {
                padding-right: 0;
            }

            .middle {
                @include flex-grow(1);

                padding-left: 0.1rem;
            }

            .end {
                @include flexbox();
                @include align-self(flex-end);
                @include align-items(center);
                @include align-content(center);

                padding-right: 0.25rem;
            }

            .locked-icon,
            .group-name {
                @include flexbox();
                @include align-self(flex-start);
                @include align-items(center);
                @include align-content(center);
                @include justify-content(center)
            }

            .locked-icon {
                width: 26px;
                text-align: left;
                cursor: not-allowed;

                .icon {
                    justify-content: left;
                }

                .icon {
                    color: $grey-light;
                }

                &.locked {
                    .icon {
                        color: $purple;
                    }
                }

                &.can-lock {
                    cursor: pointer;

                    &:hover {
                        .icon {
                            color: $green;
                        }
                    }
                }
            }

            .group-name {
                position: relative;
                top: 0.04rem;
            }
        }

        .environment-group-body {
            .group-settings {
                padding: 0.75rem;
                border-bottom: solid 1px $tile-inner-border-alt1;

                label {
                    font-size: 0.9rem;
                }

                .control.group-name {
                    max-width: 350px;
                }
            }
        }
    }
}
