@charset "utf-8";
@import "src/scss/common";

.request-elevated-access {
    @include flexbox();
    @include flex-grow(0);
    @include justify-content(stretch);
    @include align-items(flex-start);
    @include align-content(flex-start);
    @include flex-direction(row);

    border: dashed 1px $blue;
    padding: 0.75rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    span {
        color: $blue;
        font-weight: 500;
        font-size: 1rem;
        margin: 0;
    }

    .icon {
        margin-right: 0.2rem;
    }
}
