@charset "utf-8";

@import "../../../scss/common";


.task-bar-menu-icon {
    margin-right: 1em;
}

.promote-complex {
    color: white !important;
    display: flex;
    cursor: pointer;
    gap: 1em;

    .indicator {
        display: flex;
        flex-direction: row;
        font-size: 10px;
        align-items: center;
        gap: 1em;

        background-color: $upwire-green-3;
        border: 1px solid $upwire-green-1;

        color: $text-light;
        border-radius: 6px;
        overflow: hidden;
        position: relative;

        &.outdated {
            background-color: $upwire-yellow-2;
            border-color: $upwire-yellow-1;
        }

        .name, .value {
            padding: 3px 4px;
            position: relative;
            top: 0.5px;
            min-height: 100%;
        }

        .name {
            text-transform: uppercase;
            font-weight: bold;
            color: white;
        }

        .value {
            color: $text-strong;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
}

.task-bar {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(space-between);

    background-color: $dark-blue-even-darker;
    color: $white;
    padding: 0.15rem 0.55rem;
    height: 45px;
    align-items: center;

    line-height: 1em;


    .names {
        flex: 1 0 0;
        font-size: 1.1rem;
        font-weight: 600;
        display: flex;
        gap: 1em;
        align-items: center;
        justify-content: center;
    }
}

.task-actions {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(flex-end);

    font-size: 1.1rem;

    .action {
        padding: 0.25rem;

        .icon {
            cursor: pointer;

            &:hover,
            &:active {
                color: #EEE;
            }
        }
    }
}
