@charset "utf-8";

@import "src/scss/common";

.pg-phase-tabs {
    position: relative;
    bottom: -1px;
    width: 100%;

    ul {
        display: flex;
        width: 100%;

        .separator {
            flex: 1 0 100px !important;
        }

        li {
            position: relative;
            padding: 0.15rem 0 0 0;


            .tab-label {
                display: flex;
                align-items: center;
                gap: 0.5em;

                height: 40px;

                padding: 0 1rem;
                margin-right: 0.25rem;
                background: $tile-bg-alt2;
                border: solid 1px #EEE;

                color: $grey-dark;

                -webkit-border-top-left-radius: 10px;
                -webkit-border-top-right-radius: 10px;
                -moz-border-radius-topleft: 10px;
                -moz-border-radius-topright: 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;

                > div {
                    max-width: 140px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:hover,
                &:active {
                    cursor: pointer;

                    background-color: $tile-bg-alt1;
                }
            }

            &.is-active {
                .tab-label {
                    border-bottom: solid 1px $tile-bg-alt1;
                    background-color: $tile-bg-alt1;
                    color: $black;
                }
            }

            &.pg-phase-new-tab {
                > div {
                    padding: 0 0.5rem;
                }
            }

            .tab-actions {
                @include flexbox();
                @include align-items(center);
                @include justify-content(center);
                @include flex-direction(column);

                position: absolute;
                bottom: 1px;
                right: 9px;
                color: $grey-light;

                display: none;

                .action {
                    cursor: pointer;

                    font-size: 0.75rem;
                    line-height: 1.1rem;
                    height: 1.1rem;
                    width: 1.1rem;

                    &:hover {
                        color: var(--hover-color);
                    }
                }
            }
        }

        li:hover {
            .tab-actions {
                @include flexbox();
            }
        }
    }
}
