@import "src/scss/common";

.data-variable-fields {
    .data-variable-field-container {
        padding-bottom: 10px;

        .data-variable-field {
            @include flexbox();
            @include flex-grow(0);
            @include align-items(center);
            @include justify-content(space-between);

            .field-type,
            .field-name,
            .field-default {
                @include flex-grow(1);
                padding-right: 10px;
            }

            .field-type {
                width: 20%;
            }

            .field-required {
                @include flex-grow(1);
                padding-right: 10px;
            }

            .field-required {
                @include flexbox();
                @include flex-grow(0);
                @include align-items(center);
                @include justify-content(center);

                width: 13%;
            }

            .field-actions {
                @include flex-shrink(1);
                @include flex-grow(0);
            }
        }
    }
}

.data-variable-header-fields {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(space-between);

    color: #777;
    font-weight: 600;
    font-size: 0.90rem;

    .padding {
        @include flex-grow(1);
    }

    .field-required {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(center);

        padding-right: 20px;
        width: 10%;
    }

    .field-actions {
        @include flex-shrink(1);
        @include flex-grow(0);

        width: 40px;
    }
}

.data-variable-field-new {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(flex-end);

    .new-action-label {
        margin-right: 10px;
        text-align: right;
        color: #888;
        font-weight: 600;
        font-size: 1rem;
    }
}

.drag-and-drop-variable-analyzer-container {
    display: flex;
    justify-content: center;
    margin: 30px 0;

    .drag-and-drop-variable-analyzer {
        .drag-area {
            background-color: $cs8;
        }
    }
}
