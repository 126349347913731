@charset "utf-8";
@import "src/scss/common";


.in-place-input {
    position: relative;
    overflow: visible;

    input {
        background-color: $white;
        border-color: $tile-bg-alt3;
        cursor: pointer;
        box-shadow: inherit;

        &:focus {
            cursor: text;
        }
    }

    &.edit-disabled {
        input,
        input:focus {
            cursor: not-allowed;
            outline: none;
            caret-color: transparent;
        }

        input:focus,
        input:hover {
            outline-style: none;
            box-shadow: none;
            border-color: transparent;
            border-color: $tile-bg-alt3;
        }
    }

    &.is-secret {
        input,
        input:focus {
            -webkit-text-security: square;
        }
    }

    .edit-errors,
    .edit-controls {
        display: none;
    }

    &.editing {
        input {
            border-color: $grey-dark;

            &:focus {
                border-color: $blue;
            }
        }

        .edit-errors,
        .edit-controls {
            position: absolute;
            z-index: 999;
        }

        .edit-errors {
            @include flexbox();
            @include flex-grow(0);
            @include justify-content(stretch);
            @include align-items(flex-start);
            @include align-content(flex-start);
            @include flex-direction(row);

            left: 0;

            margin: 3px 2px 0 0;

            // Leave space for controls.
            margin-right: 70px;

            .message-body {
                padding: 0.25rem 0.5rem;
                font-size: 0.9rem;
            }
        }

        .edit-controls {
            @include flexbox();
            @include flex-grow(0);
            @include justify-content(stretch);
            @include align-items(flex-start);
            @include align-content(flex-start);
            @include flex-direction(row);

            right: 0;

            button {
                margin: 3px 0 0 2px;
            }
        }
    }
}
