$element-highlight-color: #536dff;
$element-selected-color: #fc5768;
$element-highlight-text-color: #fff;

.draggable-element {
    position: relative;
    border-radius: 4px;

    &.image-element {
        display: inline-block;

        > img {
            display: block;
        }
    }

    // REMOVE THIS so we aren't breaking the Froala rules
    a[target="_blank"] {
        display: none !important;
    }

    // So that text areas, for example, aren't translucent and weird
    &.is-dragging {
        background: #fff;
    }

    &.is-active:before,
    &:hover:before,
    &.is-dragging:before {
        border: solid 3px $element-highlight-color;
        content: ' ';
        display: block;
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        border-radius: 4px;
    }

    &.is-active:before {
        border-color: $element-selected-color;
    }

    .controls {
        display: none;
        position: absolute;
        right: -3px;
        top: -28px;
        z-index: 99;

        .button {
            padding: 8px;
            height: auto;
            background: $element-highlight-color;
            color: #fff;
            border: none;
        }
    }

    &.is-active .controls .button {
        background: $element-selected-color;
    }

    &.is-active > .controls,
    &:hover > .controls,
    &.is-dragging > .controls {
        display: block;
    }
}
